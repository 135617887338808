import Divider from "./divider";
import ReactLoading from "react-loading";
import { BsFillCheckCircleFill } from "react-icons/bs";

function DetailItem(props) {
  function getWidgetData() {
    if (props.isCurrentOperation) {
      return (
        <div className="loading">
          <ReactLoading type="spin" color="darkorange" width={"24px"} />
        </div>
      );
    }
    if (
      `${new Date(props.value)}` === `${new Date("1601-01-01T00:00:00.000Z")}`
    ) {
      return (
        <div className="loading">
          <BsFillCheckCircleFill color="grey" size="24px" />
        </div>
      );
    }
    if (
      `${new Date(props.value)}` !== `${new Date("1601-01-01T00:00:00.000Z")}`
    ) {
      return (
        <p className="">{`${new Date(props.value).toLocaleTimeString()}`}</p>
      );
    }
  }
  return (
    <div>
      <div className="detail-item">
        <h4>{props.title}</h4>
        <div
          className="vertical-div"
          style={{
            backgroundColor: "var(--text-color)",
            justifySelf: "stretch",
          }}
        ></div>
        {getWidgetData()}
      </div>
      <Divider />
    </div>
  );
}

export default DetailItem;

export const converyorOperations = {
  conveyor_step_1: "Tray present",
  conveyor_step_2: "Conveyor started",
  conveyor_step_3: "Stopper down",
  conveyor_step_4: "Tray identified(RFID)",
  conveyor_step_5: "Tray at the pickup point",
  conveyor_step_6: "Conveyor stopped",
};

export const assemblyOperations = {
  assembly_step_1: "Part picked from the tray",
  assembly_step_2: "Part placed between clamping cylinders",
  assembly_step_3: "Base Part clamped",
  assembly_step_4: "Inspection done",
  assembly_step_5: "First part placed",
  assembly_step_6: "Second part placed",
  assembly_step_7: "Screwing the parts",
  assembly_step_8: "Screwing done",
};

export const packagingOperations = {
  packaging_step_1: "Part placed in the box",
  packaging_step_2: "Box picked by the robot",
  packaging_step_3: "Box flaps closed",
  packaging_step_4: "Taping done",
  packaging_step_5: "Printing done",
};

export const errorCodes = {
  503: "Socket Connection Failed, Trying To Reconnect",
  404: "MPRC is off",
};

import DetailItem from "./detail-item";
import Divider from "./divider";

function Stations(props) {
  return (
    <div className="stations">
      <img src={props.img} alt={props.title} />
      <h2>{props.title}</h2>
      <Divider />
      {Object.keys(props.operations).map((operation, index) => (
        <DetailItem
          title={props.operations[operation]}
          value={props.data.data[operation]}
          isCurrentOperation={props.data.current_operation === operation}
        />
      ))}
    </div>
  );
}

export default Stations;

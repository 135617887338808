import { useEffect } from "react";
import { socket } from "../utils/socket";
import Stations from "../utils/stations";
import {
  assemblyOperations,
  converyorOperations,
  errorCodes,
  packagingOperations,
} from "../utils/utils";
import { useState } from "react";
import Lottie from "react-lottie";
// import * as NetworkAnimation from "./network.json";
// import * as MachineAnimation from "./machine.json";

function DashboardScreen() {
  const [mprcData, setMprcData] = useState({
    data: {
      conveyor_step_1: "1601-01-01T00:00:00.000Z",
      conveyor_step_2: "1601-01-01T00:00:00.000Z",
      conveyor_step_3: "1601-01-01T00:00:00.000Z",
      conveyor_step_4: "1601-01-01T00:00:00.000Z",
      conveyor_step_5: "1601-01-01T00:00:00.000Z",
      conveyor_step_6: "1601-01-01T00:00:00.000Z",
      assembly_step_1: "1601-01-01T00:00:00.000Z",
      assembly_step_2: "1601-01-01T00:00:00.000Z",
      assembly_step_3: "1601-01-01T00:00:00.000Z",
      assembly_step_4: "1601-01-01T00:00:00.000Z",
      assembly_step_5: "1601-01-01T00:00:00.000Z",
      assembly_step_6: "1601-01-01T00:00:00.000Z",
      assembly_step_7: "1601-01-01T00:00:00.000Z",
      assembly_step_8: "1601-01-01T00:00:00.000Z",
      packaging_step_1: "1601-01-01T00:00:00.000Z",
      packaging_step_2: "1601-01-01T00:00:00.000Z",
      packaging_step_3: "1601-01-01T00:00:00.000Z",
      packaging_step_4: "1601-01-01T00:00:00.000Z",
      packaging_step_5: "1601-01-01T00:00:00.000Z",
      power_on: true,
      robot_status: "",
      tool_changer: "",
      progress: "",
    },
    current_operation: "",
  });

  const [errorCode, setErrorCode] = useState(503);
  useEffect(() => {
    socket.on("mprc", (data) => {
      setMprcData(data);

      if (data.data.power_on) {
        console.log("MPRC Power On", data.data.power_on);
        setErrorCode(0);
      } else {
        console.log("MPRC Power Off", data.data.power_on);
        setErrorCode(404);
      }
    });
    socket.io.on("error", (error) => {
      console.log(error.message);
      if (error.message === "xhr poll error") {
        setErrorCode(503);
      }
    });
  }, []);

  function getMachineAnimation(code) {
    switch (code) {
      case 503:
        return "./images/network.png";
      case 404:
        return "./images/machine.png";

      default:
        return "./images/network.png";
    }
  }
  return (
    <div className="main-div">
      <div className="header">
        <div className="image-div">
          <img className="image-div" src="./images/fsmlogo.png" />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1>MPRC Dashboard</h1>
        </div>
        <div style={{ textAlign: "right" }}>
          <p>Role : Supervisor</p>
        </div>
      </div>
      <div className="detail-div">
        {errorCode == 0 ? (
          <div>
            <div className="status_div">
              <div className="power_status_div">
                <h3>MPRC Power : </h3>
                <div
                  className="power_status_button"
                  style={{
                    backgroundColor: mprcData.data.power_on ? "green" : "red",
                  }}
                ></div>
              </div>
            </div>
            <div className="station-div">
              <Stations
                img="./images/conveyor_station.png"
                title="Conveyor Station"
                operations={converyorOperations}
                data={mprcData}
              />
              <Stations
                img="./images/assembly_station.png"
                title="Assembly Station"
                operations={assemblyOperations}
                data={mprcData}
              />
              <Stations
                img="./images/packaging_station.png"
                title="Packaging Station"
                operations={packagingOperations}
                data={mprcData}
              />
            </div>
            <div className="extra-details-div">
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <h3>Robot Status : </h3>
                <div
                  className={
                    mprcData.data.robot_status === ""
                      ? "idle"
                      : mprcData.data.robot_status
                  }
                >
                  {mprcData.data.robot_status.toUpperCase()}
                </div>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <h3>Tool Changer : </h3>
                <div
                  className={
                    mprcData.data.tool_changer === ""
                      ? "none_tool_changer"
                      : "tool_changer"
                  }
                >
                  {mprcData.data.tool_changer.replace("_", " ").toUpperCase()}
                </div>
              </div>
            </div>
            {mprcData.data.progress.length > 0 && (
              <div className={"status"}>{mprcData.data.progress}</div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <img
              src={getMachineAnimation(errorCode)}
              style={{ display: "inline" }}
              alt={errorCodes[errorCode]}
            />
            <h2>{errorCodes[errorCode]}</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardScreen;

import DashboardScreen from "./screens/dashboard/screen";
import "./screens/utils/Application.css";

function App() {
  return (
    <div className="background">
      <DashboardScreen />
    </div>
  );
}

export default App;
